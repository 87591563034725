.youtube{
    display: block;
    font-weight: 500;
    color: white;
    background-image: linear-gradient(red,darkred);
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;    
}

.youtube:hover{    
    background-image: linear-gradient(darkred,darkred);    
}

  
.twitch{
    display: block;
    font-weight: 500;
    color: white;
    background-image: linear-gradient(purple,#660050);
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;    
}

.instagram{
  display: block;
  font-weight: 500;
  color: white;
  background-image: linear-gradient(#ffb04d,#b630b6);
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;    
}

.instagram:hover{
  background-image: linear-gradient(#b630b6,#b630b6);
}

.pink{
  display: block;
  font-weight: 500;
  color: white;
  cursor: pointer;
  background-color: #b630b6;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px; 
}

.pink:hover{
  background-color: #851d85;
}

.twitter{
  display: block;
  font-weight: 500;
  color: white;
  background-color: #1d9bf0;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;    
}

.twitter:hover{
  background-color: #036aaf;
}

.darkBlueBt{
  display: block;
  font-weight: 500;
  color: white;
  background-color: #036aaf;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 20px;    
}

.darkBlueBt:hover{
  background-color: #015d9b;
}

.greenInsideButton{
  display: block;
  font-weight: 500;
  color: white;
  background-color: #288745;
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;    
}

.greenInsideButton:hover{
  background-color: #155528;
}

.goToDeck{
    display: block;
    font-weight: 500;
    color: white;
    background-color: #288745;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;    
}

.goToDeck:hover{
    background-color:  #38b760;
}

.twitch:hover{
    background-image: linear-gradient(#660050,#660050);
}

 .flip-box {
    background-color: transparent;
    width: 202px;
    height: 270px;
    
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-box-front {
    background-color: transparent;
    color: black;
  }
  
  /* Style the back side */
  .flip-box-back {
    background-color: #333333;
    color: white;
    transform: rotateY(180deg);
  } 