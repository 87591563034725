.playtestPage img{
  margin:2px
}
.playtest{
}

.playtestPageButton{
    display: inline-block;
    background-color: #288745;
    color: white;
    padding: 5px;
}
.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.playtestPageButton:hover{
    background-color: #38b760;
    cursor:pointer;
}

.playtestPageImageCenterText {
    background-color: #288745;
    color: white;
    font-size: 16px;
    padding: 6px 12px;
    cursor:pointer;
  }

  .playtestPageContainer{
      position: relative;
      width: 20%;
  }

  
  .playtestPageContainer:hover .deckfolderimage {
      opacity: 0.3;
    }

  .playtestPageContainer:hover .playtestPageMiddle {
     opacity: 1;
  }

  .playtestPageContainer:hover .playtestPageMiddle2 {
     opacity: 1;
  }


.playtestPageMiddle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 95%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
}

.playtestPageMiddle2 {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 220%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
}

.playtestPageImageCenterText:hover {
    background-color: #38b760;
}

.toGraveButton{
}

