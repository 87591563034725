#animate-area {  
  background-image: url("../../assets/commandTower.png");
  background-position: 0px 0px;
  background-repeat: repeat-x;
  
}


#bg-area {  
  background-image: url("../../assets/commandTower.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-position-x: 50%;  
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 95% 0;
  }
}

.specialFont {
  text-align: center;
  font-size: large;
  font-family: 'MyFont';
  background-color: #333333;
  border-radius: 4px;
  color:#f0d349;
  font-weight: 650;
  src: local('MyFont'), url("../../fonts/BRLNSB.woff") format('woff');
}

.scaleAnimation{
  animation: fadein 2s, scaleToOneX 2s;
}

.littleRotationLeft{
  animation: fadein 2s, leftRotation 1.5s;
}

.littleRotationRight{
  animation: fadein 2s, rightRotation 1.5s;
}

@keyframes scaleToOneX {
  from {
    transform: scale(0.76);
  }  
  
  to {
    transform: scale(1);
  }  
}


@keyframes leftRotation {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rightRotation {
  from {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(0deg);
  }
}